// External Dependencies
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

// Internal Dependencies
import BlogPreview from '../components/BlogPreview';
import Layout from '../components/layout';
import Meta from '../components/Meta';

// Local Variables
const propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({}).isRequired,
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

// Component Definition
const Blog = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <Layout variant="blog">
      <Meta title="Blog" />

      <div id="blog">
        {posts.map(({ node }) => {
          const {
            author,
            cover_image,
            date,
            title,
          } = node.frontmatter;

          return (
            <BlogPreview
              author={author}
              date={date}
              excerpt={node.excerpt}
              key={node.fields.slug}
              image={cover_image}
              slug={node.fields.slug}
              title={title}
            />
          );
        })}
      </div>

    </Layout>
  );
};

export default Blog;

Blog.propTypes = propTypes;

export const pageQuery = graphql`query BlogPageQuery {
  allMarkdownRemark(
    sort: { fields: [frontmatter___date], order: DESC }
    limit: 100
    filter: { fields: { collection: { eq: "blog" } } }
  ) {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          author
          cover_image {
            id
            childImageSharp {
              gatsbyImageData(width: 1240, quality: 100, layout: CONSTRAINED)
            }
          }
          date(formatString: "MMMM D, YYYY")
          title
        }
      }
    }
  }
}
`;
