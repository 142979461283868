import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { kebabCase } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Local Variables
const propTypes = {
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({}),
    }),
  }).isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

// Component Definition
const BlogPreview = ({
  author,
  date,
  excerpt,
  image,
  slug,
  title,
}) => {
  const imageData = getImage(image.childImageSharp.gatsbyImageData);

  return (
    <Link
      className="blog-preview"
      to={`/blog${slug}`}
    >
      <article>
        <GatsbyImage alt={`blog-preview-image-for-${kebabCase(title)}`} image={imageData} />
        <div className="blog-preview-content">
          <div className="blog-date">{date}</div>
          <h2>
            {title}
          </h2>
          <div className="blog-date">by {author}</div>

          <p className="excerpt">{excerpt}</p>
        </div>
      </article>
    </Link>
  );
};

BlogPreview.propTypes = propTypes;

export default BlogPreview;
